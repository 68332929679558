import {
  MEDICAL_CENTER_ATTACHMENTS,
  MEDICAL_CENTER_NAME,
} from "../constants/constant";

export interface IProactMedicalCenterValidators {
  [MEDICAL_CENTER_NAME]: {
    required: string;
  };
  [MEDICAL_CENTER_ATTACHMENTS]: {
    required: string;
    validate: any;
  };
}

export const proactMedicalCenterValidators: IProactMedicalCenterValidators = {
  [MEDICAL_CENTER_NAME]: {
    required: "Please enter medical center name",
  },
  [MEDICAL_CENTER_ATTACHMENTS]: {
    required: "Please select medical center image",
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size < 2 * 1024 * 1024 || "File size should be less than 2MB"
        );
      },
      fileType: (file: FileList) =>
        /jpeg|png/.test(file[0].type) || "Only JPEG/PNG files are allowed",
    },
  },
};
