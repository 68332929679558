import { FC, useState } from "react";
import styles from "./pagination.module.scss";

interface IPagination {
  setDataPerPage?: (value: number) => void;
  pageIndexOptions?: number[];
  pageIndex?: number;
  setPageIndex?: (value: number) => void;
}
const Pagination: FC<IPagination> = ({
  setDataPerPage,
  pageIndexOptions,
  pageIndex,
  setPageIndex,
}) => {
  const numberPagesArray = [10, 15, 20];
  // const pageOptions = [0, 1, 2, 3];
  // const [pageNum, setPageNum] = useState(pageOptions[0]);

  return (
    <div className={styles.paginationSection}>
      <>
        {pageIndexOptions?.map((pageOption) => (
          <div
            className={
              pageIndex === pageOption
                ? styles.selectedPage
                : styles.paginationButton
            }
            key={pageOption}
            onClick={() => setPageIndex && setPageIndex(pageOption)}
          >
            {pageOption}
          </div>
        ))}
      </>
      <div className={styles.setPageSizeSection}>
        <p className={styles.setPageSizeText}>Listing</p>
        <select
          className={styles.selectPageSize}
          // value={pageSize}
          onChange={(event) => {
            setDataPerPage && setDataPerPage(Number(event.target.value));
            setPageIndex && setPageIndex(1);
          }}
        >
          {numberPagesArray.map((numberPageArray) => (
            <option key={numberPageArray} value={numberPageArray}>
              {numberPageArray}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Pagination;
