import { FC } from "react";
import AttachFiles from "../../../../components/common/attach-files/single-file/AttachSingleFile";
import {
  MEDICAL_CENTER_ATTACHMENTS,
  MEDICAL_CENTER_NAME,
} from "../../../../constants/constant";
import { proactMedicalCenterValidators } from "../../../../form-validators/proactMedicalCenterValidators";
import { isDataUri } from "../../../../utils/utils";
import styles from "./medicalCenterCard.module.scss";

interface IMedicalCenterCard {
  register?: any;
  registers?: any;
  errors?: any;
  setValue: any;
  name?: any;
  rules?: any;
  control?: any;
  error?: any;
  item?: any;
  trigger?: any;
  fileList?: any;
  watch?: any;
  index?: any;
  imgFile?: any;
}

const MedicalCenterCard: FC<IMedicalCenterCard> = ({
  item,
  register,
  errors,
  watch,
  index,
}) => {
  const image = watch();

  const handleChange = (e: any) => {
    const value = e.target.value;
    if (value.length === 1 && value === " ") {
      e.target.value = "";
    } else if (
      value.length > 1 &&
      value[0] === " " &&
      value[value.length - 1] === " "
    ) {
      e.target.value = value.trim();
    }
  };

  image[`${item._id}`] &&
    console.log(
      "image>>",
      image[`${item._id}`][`${MEDICAL_CENTER_ATTACHMENTS}`]
    );

  let imgFile = image[`${item._id}`]
    ? image[`${item._id}`][`${MEDICAL_CENTER_ATTACHMENTS}`]
    : {};

  return (
    <>
      <div className={styles.cardContainer}>
        <p className={styles.title}>Medical Center {index + 1}</p>
        <div className={styles.labelFields}>
          <label
            className={styles.labelText}
            htmlFor={`${item?.id}.${MEDICAL_CENTER_NAME}`}
          >
            Medical Center Name <span className="asterick">*</span>
          </label>
          <div className={styles.fieldContainer}>
            <input
              type="text"
              className={styles.inputField}
              placeholder="Enter medical center"
              defaultValue={item.mc_name}
              {...register(
                `${item?._id}.${MEDICAL_CENTER_NAME}`,
                proactMedicalCenterValidators[MEDICAL_CENTER_NAME]
              )}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <p className="errorText">
              {errors[item?._id]?.[MEDICAL_CENTER_NAME] &&
                errors[item?._id]?.[MEDICAL_CENTER_NAME].message}
            </p>
          </div>
        </div>
        <div className={styles.labelFields}>
          <label className={styles.labelText}>
            Medical Center Image <span className="asterick">*</span>
          </label>
          <div className={styles.fieldContainer}>
            <AttachFiles
              register={register}
              fileKey={`${item?._id}.${MEDICAL_CENTER_ATTACHMENTS}`}
              fileList={
                image[`${item._id}`]
                  ? image[`${item._id}`][`${MEDICAL_CENTER_ATTACHMENTS}`]
                  : {}
              }
              id={`file-${item?._id}`}
              validation={
                imgFile && isDataUri(imgFile)
                  ? {}
                  : imgFile[0]?.length > 0
                  ? {}
                  : proactMedicalCenterValidators[MEDICAL_CENTER_ATTACHMENTS]
                // : proactMedicalCenterValidators[MEDICAL_CENTER_ATTACHMENTS]
              }
            />
            {errors[item._id]?.[MEDICAL_CENTER_ATTACHMENTS] && (
              <div className={styles.errorContainer}>
                <p className="dashboardFormError">
                  {errors[item._id]?.[MEDICAL_CENTER_ATTACHMENTS]?.message}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MedicalCenterCard;
