import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../../components/common/button/Button'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { IProactMedicalCenterForm } from '../../../interfaces/interfaces'
import {
  createMedicalCenters,
  getAllProactMedicalCenter,
} from '../../../redux/features/mobile-app-config/proactMobileAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { dataURI, isDataUri } from '../../../utils/utils'
import MedicalCenterCard from './medical-center-card/MedicalCenterCard'
import styles from './proactMedicalCenter.module.scss'
import Loader from '../../../components/common/spinner/Loader'

const ProactMedicalCenter: FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading, proactMedicalCenterData } = useAppSelector(
    (state) => state.proactMobileConfig
  )

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<IProactMedicalCenterForm>()

  const onSubmit: SubmitHandler<IProactMedicalCenterForm> = async (
    data: any
  ) => {
    const payload = Object.values(data)

    const base64Array = await Promise.all(
      payload.map(async (item: any) => {
        // console.log(file.name)
        if (isDataUri(item?.mc_image)) {
          return item
        } else {
          let file = item.mc_image[0]
          const base64DataUri = await dataURI(file)
          return {
            ...item,
            mc_image: base64DataUri,
          }
        }
      })
    )
    console.log('base64Array>>', base64Array)

    dispatch(createMedicalCenters(requestGenerator(base64Array)))
  }

  useEffect(() => {
    dispatch(getAllProactMedicalCenter(requestGenerator({})))
  }, [dispatch])

  let arr = [
    { name: 'Medical Center 1', _id: 0 },
    { name: 'Medical Center 2', _id: 1 },
    { name: 'Medical Center 3', _id: 2 },
    { name: 'Medical Center 4', _id: 3 },
    { name: 'Medical Center 5', _id: 4 },
  ]
  useEffect(() => {
    if (proactMedicalCenterData.length > 0) {
      let formdata = { ...proactMedicalCenterData }
      reset(formdata)
    }
  }, [reset, proactMedicalCenterData])

  return (
    <>
      {isLoading && <Loader />}

      <div className={styles.meedicalCenterContainer}>
        <p className={styles.title}>HEALTHBOX Medical Center</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.cardsContainer}>
            {arr &&
              arr.length > 0 &&
              arr.map((item: any, index: any) => {
                return (
                  <MedicalCenterCard
                    item={item}
                    key={index}
                    index={index}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    watch={watch}
                  />
                )
              })}
          </div>
          <div className={styles.buttonContainer}>
            <Button title="Submit" type="submit" />
            <Button title="Reset" type="reset" customClass={styles.resetBtn} />
          </div>
        </form>
      </div>
    </>
  )
}

export default ProactMedicalCenter
