import {
  DOCTOR_IMAGE,
  DOCTOR_NAME,
  DOCTOR_SPECIALITY,
} from '../constants/constant'

export const proactDoctorValidations = {
  [DOCTOR_NAME]: {
    required: 'Please doctor name',
    minLength: {
      value: 2,
      message: 'Doctor name should have at least 2 characters',
    },
  },
  [DOCTOR_SPECIALITY]: {
    required: 'Please enter specialty',
    maxLength: {
      value: 20,
      message: 'Specialty should have at most 20 characters',
    },
  },
  [DOCTOR_IMAGE]: {
    required: 'Please doctor image',
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 || 'File size should be less than 2MB'
        )
      },
      fileType: (file: FileList) =>
        /jpeg|png/.test(file[0].type) || 'Only JPEG/PNG files are allowed',
    },
  },
}
