import { FC, useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import Button from '../../../components/common/button/Button'
import ProactNewsCard from '../../../components/common/proact-news-card/ProactNewsCard'
import { proactNewsDataArray } from '../../../constants/data'
import { IProactNewsCardForm } from '../../../interfaces/interfaces'
import styles from './proactNews.module.scss'
import { useAppSelector, useAppDispatch } from '../../../hooks/index'
import {
  createProactNews,
  getAllProactNews,
} from '../../../redux/features/mobile-app-config/proactMobileAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Loader from '../../../components/common/spinner/Loader'

interface IProactNews {}
const ProactNews: FC<IProactNews> = () => {
  const { isLoading, proactNewsData } = useAppSelector(
    (state) => state.proactMobileConfig
  )
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IProactNewsCardForm>()

  useEffect(() => {
    dispatch(getAllProactNews(requestGenerator({})))
  }, [dispatch])
  const onSubmit: SubmitHandler<IProactNewsCardForm> = (data) => {
    const payload = Object.values(data)
    dispatch(createProactNews(requestGenerator(payload)))
  }

  useEffect(() => {
    if (proactNewsData.length > 0) {
      let formdata = { ...proactNewsData }
      reset(formdata)
    }
  }, [reset, proactNewsData])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.mainContainer}>
        <p className={styles.title}>HEALTHBOX News</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.cardComponent}>
            {proactNewsDataArray?.map((item: any, i: number) => {
              return (
                <ProactNewsCard
                  item={item}
                  index={i}
                  key={i}
                  register={register}
                  errors={errors}
                />
              )
            })}
          </div>
          <div className={styles.buttonConatiner}>
            <Button title="Submit" type="submit" />
            <Button
              title="Reset"
              type="reset"
              customClass={styles.resetButtonStyle}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default ProactNews
