import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { colors } from '../../../../constants/color'
import { useAppDispatch } from '../../../../hooks'
import { getAllMedicalCenterById } from '../../../../redux/features/medical-center/medicalCenterAsynActions'
import { getPackageById } from '../../../../redux/features/package/packageAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { utcToDate } from '../../../../utils/utils'
import Divider from '../../divider/Divider'
import { CloseIcon } from '../../svg-components'
import styles from './notesPopup.module.scss'

interface IPropsData {
  popData?: any;
  handleClose?:any
  
}
  const NotesPopup: FC<IPropsData> = ({ popData,handleClose }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (location?.pathname === '/medicalcenter') {
      dispatch(getAllMedicalCenterById(requestGenerator(popData?._id)))
    } else {
      dispatch(getPackageById(requestGenerator(popData?._id)))
    }
  }, [dispatch])

  return (
    <>
      <div className={styles.notesPopupContainer}
      
      onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}

        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>Notes</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.dateInfo}>
            {location.pathname === '/package' ? (
              <>
                <p className={styles.dateText}>Latest Updated</p>
                <p className={styles.spanText}>
                  {utcToDate(popData?.updatedAt)}
                </p>
                <p className={styles.descriptionText}>{popData?.description}</p>
              </>
            ) : (
              <>
                <p className={styles.dateText}>Latest Updated</p>
                <p className={styles.spanText}>
                  {' '}
                  {utcToDate(popData?.updatedAt)}
                </p>
                <p className={styles.descriptionText}>{popData?.notes}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default NotesPopup
