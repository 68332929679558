import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';
import React, { FC } from 'react';
import { Cols } from '../../../interfaces/interfaces';
import { DeleteIcon, EditIcon, SortDown } from '../svg-components';
import styles from './table.module.scss';
import { useLocation } from 'react-router-dom';
import ToggleSwitch from '../toggle-switch/ToggleSwitch';

interface ITable {
  tableHeaderData: Column<Cols>[];
  tableRowData: Cols[];
  handleNotes?: any;
  handleAction?: any;
  handleDetails?: any;
  handleDeleteAction?: any;
  handleCreateUsers?: any;
  handleActiveMC?: any;
  toogleValue?: number;
}

const Table: FC<ITable> = ({
  tableHeaderData,
  tableRowData,
  handleNotes,
  handleAction,
  handleDetails,
  handleDeleteAction,
  handleCreateUsers,
  handleActiveMC,
  toogleValue,
}) => {
  const data: Cols[] = tableRowData;
  const columns: Column<Cols>[] = tableHeaderData;
  const options: TableOptions<Cols> = {
    data,
    columns,
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options, useGlobalFilter, useSortBy);
  const location = useLocation();

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    fontWeight: '600',
                    fontSize: '12px',
                  }}
                >
                  {column.render('Header')}
                  {/* {column.isSorted && column.isSortedDesc ? (
                    <span style={{ paddingLeft: "7px", cursor: "pointer" }}>
                      <SortDown />
                    </span>
                  ) : (
                    <span style={{ paddingLeft: "7px", cursor: "pointer" }}>
                      <SortDown />
                    </span>
                  )} */}
                  {column.canSort && (
                    <span style={{ paddingLeft: '7px', cursor: 'pointer' }}>
                      <SortDown />
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {rows?.length > 0 ? (
          <tbody {...getTableBodyProps()}>
            {rows?.map((row: any) => {
              prepareRow(row);
              // console.log("row?.original", row?.original?.is_standard_pkg);
              return (
                <tr {...row.getRowProps()}>
                  {row?.cells?.map((cell: any, i: number) => {
                    return (
                      <React.Fragment key={i}>
                        {cell.column.Header === 'NOTES' ? (
                          <td
                            onClick={
                              row?.original?.description || row?.original?.notes
                                ? () => handleNotes(row?.original)
                                : () => {}
                            }
                            // style={{ cursor: "pointer", color: " #0e26a3" }}
                          >
                            {/* {row?.original?.description?.length === 0
                              ? "not View"
                              : "view"} */}
                            {row?.original?.description ||
                            row?.original?.notes ? (
                              <p className={styles.view}>View</p>
                            ) : (
                              '-'
                            )}
                          </td>
                        ) : cell.column.Header === 'DETAILS' ? (
                          <td
                            onClick={() =>
                              row?.original?.module_ids &&
                              row?.original?.module_ids.length > 0 &&
                              handleDetails(row?.original?._id)
                            }
                            style={{ cursor: 'pointer', color: ' #0e26a3' }}
                          >
                            {row?.original?.module_ids &&
                            row?.original?.module_ids.length > 0
                              ? 'View'
                              : '-'}
                          </td>
                        ) : cell.column.Header === 'ACTIONS' ? (
                          <td>
                            <span className={styles.actionIconStyle}>
                              {row?.original?.is_standard_package === true ? (
                                <EditIcon
                                  fillColor="#CDD4D8"
                                  customClass={styles.editIconStyle}
                                />
                              ) : (
                                <EditIcon
                                  fillColor="#CDD4D8"
                                  customClass={styles.iconStyle}
                                  handleClick={() => {
                                    handleAction(row?.original?._id);
                                  }}
                                />
                              )}
                              {/* > */}
                            </span>
                          </td>
                        ) : cell.column.Header === 'CREATED USERS' ? (
                          row?.original?.created_users_count !== 0 ? (
                            <td
                              onClick={() => handleCreateUsers(row?.original)}
                              style={{ cursor: 'pointer', color: ' #0e26a3' }}
                            >
                              {row?.original?.created_users_count}
                            </td>
                          ) : (
                            <td>{row?.original?.created_users_count}</td>
                          )
                        ) : cell.column.Header === 'STATUS' ? (
                          <td className={styles.toogleStyle}>
                            <ToggleSwitch
                              setIsToggled={() => {
                                handleActiveMC(row?.original);
                              }}
                              isToggled={row?.original?.is_active}
                            />
                          </td>
                        ) : cell.column.Header === 'EXPIRY DATE' ? (
                          <td className={styles.expiryDate}>
                            {row?.original?.expiry_date}
                          </td>
                        ) : (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={12} className={styles.noReordFoundText}>
                No records found
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </>
  );
};

export default Table;
