import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../../components/common/button/Button'
import { doctorArray } from '../../../constants/data'
import styles from './proactDoctor.module.scss'
import { proactDoctorValidations } from '../../../form-validators/proactDoctorConfigValidator'
import { IProactDoctorForm } from '../../../interfaces/interfaces'
import AttachFiles from '../../../components/common/attach-files/single-file/AttachSingleFile'
import { useAppDispatch, useAppSelector } from '../../../hooks/index'
import {
  DOCTOR_IMAGE,
  DOCTOR_NAME,
  DOCTOR_SPECIALITY,
} from '../../../constants/constant'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  createDoctors,
  getDoctors,
} from '../../../redux/features/mobile-app-config/proactMobileAsyncActions'
import { dataURI, isDataUri } from '../../../utils/utils'
import { CREATE_DOCTOR_TYPE } from '../../../constants/asyncActionsType'
import Loader from '../../../components/common/spinner/Loader'

const ProactDoctor: FC = () => {
  const dispatch = useAppDispatch()
  const { proactDoctorsData, isLoading } = useAppSelector(
    (state) => state.proactMobileConfig
  )

  console.log('base64Array>>')

  // get form data
  useEffect(() => {
    dispatch(getDoctors(requestGenerator({})))
  }, [dispatch])

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IProactDoctorForm>()
  const formData = watch() //watch for data

  //set default form data
  useEffect(() => {
    if (proactDoctorsData.length > 0) {
      let formdata = { ...proactDoctorsData }
      reset(formdata)
    }
  }, [reset, proactDoctorsData])

  // onsubmit
  const onSubmit = async (data: IProactDoctorForm) => {
    const formArray = Object.values(data)

    // console.log('data>>', formArray)

    const base64Array = await Promise.all(
      formArray.map(async (item: any) => {
        // console.log(file.name)
        if (isDataUri(item?.dr_image)) {
          return item
        } else {
          let file = item.dr_image[0]
          const base64DataUri = await dataURI(file)
          return {
            ...item,
            dr_image: base64DataUri,
          }
        }
      })
    )
    console.log('base64Array>>', base64Array)

    dispatch(createDoctors(requestGenerator(base64Array))).then((e) => {
      if (e.type === `${CREATE_DOCTOR_TYPE}/fulfilled`) {
        reset()
        dispatch(getDoctors(requestGenerator({})))
      }
    })
  }

  const handleChange = (e: any) => {
    const value = e.target.value
    if (value.length === 1 && value === ' ') {
      e.target.value = ''
    } else if (
      value.length > 1 &&
      value[0] === ' ' &&
      value[value.length - 1] === ' '
    ) {
      e.target.value = value.trim()
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.proactDoctorContaier}>
        <h1 className={styles.proactDoctorTitle}>HEALTHBOX Doctor</h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.proactDoctorForm}
        >
          {doctorArray.map((doctorData) => {
            let fillName: any = formData[`${doctorData.id}`]
              ? formData[`${doctorData.id}`][`${DOCTOR_IMAGE}`]
              : {}
            return (
              <div key={doctorData.id} className={styles.formContainer}>
                <div className={styles.doctorName}>{doctorData.name}</div>
                <div className={styles.formFieldContaner}>
                  <div className={styles.inputFieldContainer}>
                    <label
                      htmlFor={`${doctorData.id}.${DOCTOR_NAME}`}
                      className={styles.formLabel}
                    >
                      Doctor Name <span className="asterick">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Doctor name"
                      className={styles.formInput}
                      {...register(
                        `${doctorData.id}.${DOCTOR_NAME}`,
                        proactDoctorValidations[DOCTOR_NAME]
                      )}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                  </div>
                  {errors[doctorData.id]?.[DOCTOR_NAME] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className={styles.dashboardFormError}>
                        {errors[doctorData.id]?.[DOCTOR_NAME]?.message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContaner}>
                  <div className={styles.inputFieldContainer}>
                    <label
                      htmlFor={`${doctorData.id}.${DOCTOR_SPECIALITY}`}
                      className={styles.formLabel}
                    >
                      Specialty <span className="asterick">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Doctor specialty"
                      className={styles.formInput}
                      {...register(
                        `${doctorData.id}.${DOCTOR_SPECIALITY}`,
                        proactDoctorValidations[DOCTOR_SPECIALITY]
                      )}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                  </div>
                  {errors[doctorData.id]?.[DOCTOR_SPECIALITY] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className={styles.dashboardFormError}>
                        {errors[doctorData.id]?.[DOCTOR_SPECIALITY]?.message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContaner}>
                  <div className={styles.inputFieldContainer}>
                    <label
                      htmlFor={`${doctorData.id}.${DOCTOR_IMAGE}`}
                      className={styles.formLabel}
                    >
                      Doctor Image <span className="asterick">*</span>
                    </label>
                    <AttachFiles
                      register={register}
                      fileKey={`${doctorData.id}.${DOCTOR_IMAGE}`}
                      id={`file-${doctorData.id}`}
                      fileList={fillName}
                      validation={
                        fillName
                          ? isDataUri(fillName)
                            ? {}
                            : fillName[0]?.length > 0
                            ? {}
                            : proactDoctorValidations[DOCTOR_IMAGE]
                          : proactDoctorValidations[DOCTOR_IMAGE]
                      }
                    />
                  </div>
                  {errors[doctorData.id]?.[DOCTOR_IMAGE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className={styles.dashboardFormError}>
                        {errors[doctorData.id]?.[DOCTOR_IMAGE]?.message}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
          <div className={styles.formButtonContainer}>
            <Button title="Submit" type="submit" />
            <Button
              title="Reset"
              type="reset"
              customClass={styles.resetBtn}
              handleClick={() => reset()}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default ProactDoctor
