import { NEWS_TITLE, NEWS_DESCRIPTION } from '../constants/constant'
export interface IProactNewsValidators {
  [NEWS_TITLE]: {
    required?: string
  }
  [NEWS_DESCRIPTION]: {
    required?: string
  }
}
export const proactNewsValidators: IProactNewsValidators = {
  [NEWS_TITLE]: {
    required: 'Please enter news title',
  },
  [NEWS_DESCRIPTION]: {
    required: 'Please enter news description',
  },
}
